import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import React, { useContext } from 'react';
import Table from '@shoreag/base/Table';
import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import Spinner from '@shoreag/base/Spinner';
import { AuthContext } from '@shoreag/auth';
import { useMutation, useQuery } from 'react-apollo';
import DotsIcon from '../DotsIcon';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import roleDashboardPageQuery from '../../graphql/queries/role-dashboard-page.gql';
import updateUserRoleMutation from '../../graphql/mutations/update-user-role.gql';
import useSnackbar from '../../utilities/use-snackbar';
import { PERMISSION_ICONS } from '../RolePermissionIcon';
import { PERMISSION_ACTIONS_MAP } from '../../utilities/constants';

const RoleDashboardPage = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  const { data, loading } = useQuery(roleDashboardPageQuery, {
    fetchPolicy: 'network-only',
  });

  const [updateUserRole] = useMutation(updateUserRoleMutation);
  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  const availablePermissions = get(data, 'allAvailablePermission', []);

  const roles = [
    {
      disabled: false,
      name: 'Administrator',
      permissions: availablePermissions.map((ap) => ({
        ...ap,
        actions: ap.actions.reduce(
          (adminActions, currentActions) =>
            currentActions.length > adminActions.length
              ? currentActions
              : adminActions,
          ''
        ),
      })),
    },
    ...get(data, 'allUserRole', []),
  ];

  return (
    <Route
      header={{
        icon: 'user-check',
        rightContainer: (
          <ToolbarButton icon="plus" label="Create Role" link="/roles/create" />
        ),
        title: 'Dashboard',
        type: 'Role',
      }}
      isPrivate
    >
      <Card mx="auto" overflowX="auto" p={0} width="calc(100% - 100px)">
        {loading ? (
          <Spinner py="lg" />
        ) : (
          <Table
            header={[
              'Role',
              ...availablePermissions.map((p) => p.name),
              'Enabled',
            ]}
            rows={roles.map((role) => [
              <Box sx={{ fontWeight: 'bold' }}>
                {role.name}
                {role.name === 'Administrator' ? (
                  <></>
                ) : (
                  <Box
                    sx={{
                      float: 'right',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%) rotate(90deg)',
                    }}
                  >
                    <DropdownMenu
                      button={<DotsIcon />}
                      itemActions={[
                        `/roles/${role.id}/edit`,
                        async () => {
                          try {
                            await updateUserRole({
                              update: (client) => {
                                client.writeQuery({
                                  data: {
                                    ...data,
                                    allUserRole: data.allUserRole.map((r) => ({
                                      ...r,
                                      disabled:
                                        r.id === role.id
                                          ? !role.disabled
                                          : r.disabled,
                                    })),
                                  },
                                  query: roleDashboardPageQuery,
                                });
                              },
                              variables: {
                                ...stripTypename(role),
                                disabled: !role.disabled,
                              },
                            });

                            setSnack(
                              `Role ${role.disabled ? 'enabled' : 'disabled'}`
                            );
                          } catch (e) {
                            setErrorSnack(
                              e.graphQLErrors[0].message.replace(/ \(.+$/, '')
                            );
                          }
                        },
                      ]}
                      items={[
                        ['edit', 'Edit'],
                        [
                          role.disabled ? 'plus' : 'close',
                          role.disabled ? 'Enable' : 'Disable',
                        ],
                      ].map(renderDropdownMenuItem)}
                    />
                  </Box>
                )}
              </Box>,
              ...availablePermissions.map((ap) => {
                const rp = get(
                  role.permissions.find((p) => p.resource === ap.resource),
                  'actions',
                  '_'
                );
                return (
                  <Box title={PERMISSION_ACTIONS_MAP[rp]}>
                    {PERMISSION_ICONS[rp]}
                  </Box>
                );
              }),
              <Box as="strong" color={role.disabled ? 'error' : 'success'}>
                {role.disabled ? 'No' : 'Yes'}
              </Box>,
            ])}
            stickyColumn={0}
            tableSx={{
              border: 'none',
              display: 'flex',
              tbody: {
                display: 'flex',
                flex: 1,
              },
              tr: {
                '& td': {
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  p: 5,
                },
                '& th, & td': {
                  '&:first-child': {
                    bg: 'primary',
                    color: 'white',
                  },
                  '&:not(:first-child):nth-child(even)': {
                    bg: 'white',
                  },
                  '&:not(:first-child):nth-child(odd)': {
                    bg: 'grays.1',
                  },
                  borderBottomWidth: '0',
                  borderColor: 'gray3',
                  borderRightStyle: 'solid',
                  borderWidth: '1px',
                  display: 'flex',
                  height: '60px',
                  minWidth: '200px',
                },
                display: 'table-cell',
                flex: 1,
                minWidth: '150px',
                verticalAlign: 'top',
              },
            }}
          />
        )}
      </Card>
    </Route>
  );
};

export default RoleDashboardPage;
