import React from 'react';
import { Router } from '@reach/router';
import RoleCreatePage from '../../components/RoleCreatePage';
import RoleDashboardPage from '../../components/RoleDashboardPage';
import NotFoundPage from './404';

const RolesRouter = (props) => (
  <Router>
    <RoleCreatePage {...props} path="/roles/:userRoleId/edit" />
    <RoleCreatePage {...props} path="/roles/create" />
    <RoleDashboardPage {...props} path="/roles/" />
    <NotFoundPage default />
  </Router>
);

export default RolesRouter;
