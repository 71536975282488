import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthContext } from '@shoreag/auth';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import RoleFormSection from '../RoleFormSection';

const RoleCreatePage = ({ navigate, userRoleId }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  return (
    <Route
      header={{
        icon: 'user-check',
        title: `${userRoleId ? 'Update' : 'Create'} Role`,
        type: 'Role',
      }}
      isPrivate
    >
      <RoleFormSection navigate={navigate} userRoleId={userRoleId} />
    </Route>
  );
};

RoleCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
  userRoleId: PropTypes.string,
};

RoleCreatePage.defaultProps = {
  userRoleId: null,
};

export default RoleCreatePage;
